import {
  randomString,
  isWWW,
  initIntercom,
  getLocation,
  shutdownIntercom,
  shareCodesFromQuery,
  push,
  getQueryParams,
  identifyPostHogUser,
} from "../../util/Utils";
import { fetchEntityService } from "../../service/FetchEntityService";
import {
  socketConnectionDispatcher,
  socketDisconnectionDispatcher,
} from "./EntitySocketActions";
import {
  EntityType,
  currentEntityAction,
  currentIdAction,
  clearCurrentStateAction,
  fetchCurrentEntityAction,
  fetchCurrentListDispatcher,
} from "./CurrentActions";
import {
  checkUploadFromLocalStorageDispatcher,
  clearFileUploadListAction,
  configureLocalStorageUploadListenerDispatcher,
} from "./UploadQueueActions";
import request from "superagent";
import {
  showErrorMessageBarAction,
  resetUIStateAction,
  showUIComponentAction,
  UIComponent,
  ModalName,
  showModalAction,
} from "./UIActions";
import { deleteUserSessionDispatcher } from "./DeleteEntityActions";
import {
  signinCognito,
  signoutCognito,
  getCognitoUser,
  configureAmplify,
  signinOauth,
  oauthHostname,
} from "../../service/AmplifyLoginService";
import {
  createUserSessionDispatcher,
  sendPasswordResetLinkDispatcher,
} from "./CreateEntityActions";

import { updateEntityService } from "../../service/UpdateEntityService";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { logDebug, logError } from "../../service/ServiceUtil";
import {
  EXTENSION_OPEN_HASH,
  EXTENSION_SIGN_IN_PATH,
  syncExtensionStorageFromPixelMixer,
} from "../../service/ChromeExtensionService";
import { ListName } from "../reducer/CurrentListActionReducer";
import Cookies from "universal-cookie";

export const AccountUserRole = {
  user: "USER",
  admin: "ADMIN",
};

export const getUserRoleName = (accountUser) => {
  if (accountUser && accountUser.accountUserRole) {
    if (accountUser.accountOwner) return "Owner";
    else if (accountUser.accountUserRole === AccountUserRole.admin)
      return "Account Admin";
    else return "Account User";
  } else return "User";
};
/**
 * Refreshes only the userSession object if the user's permissions or session related
 * needs to be refreshed from the backend.
 * @param {*} sessionKey
 */
export const refreshUserSessionDispatcher = (sessionKey) => {
  return async (dispatch, getState) => {
    if (sessionKey !== null) {
      const userSession = await dispatch(
        fetchCurrentEntityAction(EntityType.UserSession, sessionKey)
      );
      dispatch(currentIdAction(EntityType.UserSession, userSession.id));
      dispatch(currentEntityAction(EntityType.UserSession, userSession));
    }
  };
};

/**
 * Loads (or unloads) all current and session state related asundry from a userSession object
 * Called when auto-loading the userSession from a createUser, resetPassword or bootstrap process.
 * @param {*} userSession
 */
export const refreshCurrentUserSessionStateDispatcher = (userSession) => {
  return (dispatch, getState) => {
    //Always set sessionKey first:
    dispatch(updateSessionKeyAction(userSession));
    if (userSession !== null) {
      dispatch(currentIdAction(EntityType.UserSession, userSession.id));
      dispatch(currentEntityAction(EntityType.UserSession, userSession));
      dispatch(
        currentIdAction("ownerAccountUser", userSession.accountUser?.id)
      );
      dispatch(currentIdAction(EntityType.Account, userSession.accountId));
      dispatch(currentEntityAction(EntityType.Account, userSession.account));
      dispatch(
        currentEntityAction(
          EntityType.IdentityProvider,
          userSession.account.identityProvider
        )
      );
      dispatch(
        currentIdAction(
          EntityType.Organization,
          userSession.account.organizationId
        )
      );
      const { ui, login } = getState();
      //TODO: Adding accountUsers here for now and can be removed once UserSearchSidebar is in place:
      if (login.isAccountUser)
        dispatch(fetchCurrentListDispatcher(ListName.accountUsers));
      if (!isWWW) {
        dispatch(
          socketConnectionDispatcher(
            userSession.sessionKey,
            userSession.accountId
          )
        );
        dispatch(fetchCurrentListDispatcher("onlineEntitySocketSessions"));
        //Check for redirect coming from oAuth sign-in as this will be handled by createUserSession and/or bootstrapUserSession:
        const redirect = getQueryParams()["redirect"];
        if (
          isAccountUpgradeRequired(
            userSession.account,
            userSession.accountUser
          ) &&
          !window?.location.pathname.startsWith("/account/")
        ) {
          push("/account/billing");
        } else if (
          !redirect &&
          (getLocation().pathname?.startsWith("/login") || ui.isBlankPage)
        ) {
          push("/");
        }
        dispatch(showModalAction(ModalName.LoginModal, false));
        dispatch(showModalAction(ModalName.CreateLoginModal, false));
        //Check for local storage uploads because the extension
        //may have fired the event before userSession was loaded:
        dispatch(checkUploadFromLocalStorageDispatcher());
        syncExtensionStorageFromPixelMixer();
      }
      const user = userSession.user;
      const datadogUser = {
        id: user.id,
        name: user.fullName,
        email: user.emailAddress,
        plan: userSession.account?.accountType,
        accountId: userSession.account?.id,
        accountUserId: userSession.accountUser?.id,
        sessionKey: userSession.sessionKey,
      };
      datadogRum.setUser(datadogUser);
      datadogLogs.setUser(datadogUser);
    } else dispatch(currentEntityAction(EntityType.UserSession, null));
  };
};

export const loginDispatcher = (emailAddress, password) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoggingIn(true));
      const coginitoUser = await signinCognito(emailAddress, password);
      //Cognito requires password reset:
      if (coginitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch(setIsLoggingIn(false));
        dispatch(sendPasswordResetLinkDispatcher(emailAddress));
      } else {
        return coginitoUser;
      }
    } catch (error) {
      dispatch(setIsLoggingIn(false));
      //Core requires password reset (TODO: remove once most power users have migrated):
      if (error.errorCode === "PasswordResetRequiredException") {
        dispatch(sendPasswordResetLinkDispatcher(emailAddress));
        dispatch(
          showErrorMessageBarAction(
            true,
            "Please check email to reset password."
          )
        );
        throw error;
      } else {
        dispatch(showErrorMessageBarAction(true, error.userMessage));
        throw error;
      }
    }
  };
};

export const logoutDispatcher = () => {
  return async (dispatch, getState) => {
    const sessionKey = getState().login.sessionKey;
    if (sessionKey) {
      await dispatch(deleteUserSessionDispatcher(sessionKey));
      dispatch(updateSessionKeyAction(null));
    }
    await signoutCognito();
    dispatch(clearCurrentStateAction());
    dispatch(clearFileUploadListAction());
    dispatch(resetUIStateAction());
    dispatch(socketDisconnectionDispatcher());
    shutdownIntercom();
    document.location = `https://${oauthHostname}/oauthSignOut?redirectUrl=${encodeURIComponent(
      window.location.origin
    )}`;
  };
};

export const setPasswordDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const user = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.User,
      null,
      formObject,
      "setPassword"
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(showModalAction(ModalName.VerifyEmailLinkModal, false));
    dispatch(showModalAction(ModalName.VerifyEmailPinModal, false));
    push("/");
    const coginitoUser = await signinCognito(
      user.emailAddress,
      formObject.password
    );
  };
};

export const resetPasswordDispatcher = (keyCode, password) => {
  return async (dispatch, getState) => {
    const formObject = { keyCode, password };
    const passwordReset = await updateEntityService.updateEntity(
      dispatch,
      getState,
      EntityType.PasswordReset,
      null,
      formObject,
      "resetPassword"
    );
    dispatch(showErrorMessageBarAction(false));
    const coginitoUser = await signinCognito(
      passwordReset.emailAddress,
      password
    );
    dispatch(createUserSessionDispatcher());
    dispatch(showModalAction(ModalName.ResetPasswordModal, false));
  };
};

/**
 * Loads the userSession when the app first inits and
 * loads userSession if the user is logged in to Cognito:
 */
export const bootstrapUserSessionAndLoginStateDispatcher = () => {
  logDebug("bootstrapUserSessionAndLoginStateDispatcher");

  return async (dispatch, getState) => {
    try {
      await dispatch(loadOauthCredentialsDispatcher());
      logDebug("loadOauthCredentialsDispatcher storage transfer complete");
    } catch (e) {
      logError("loadOauthCredentialsDispatcher storage transfer error", {}, e);
    }
    // Check if user has an active Cognito session:
    const cognitoUser = await getCognitoUser();
    const sessionKey = getState().login.sessionKey;
    // if Cognito session is active check for sessionKey:
    if (cognitoUser) {
      // If sessionKey is present, fetch userSession
      if (sessionKey) {
        try {
          const userSession = await fetchEntityService.fetchEntity(
            dispatch,
            getState,
            EntityType.UserSession,
            sessionKey,
            null,
            shareCodesFromQuery()
          );
          //If userSession is valid, refresh userSession state
          await dispatch(refreshCurrentUserSessionStateDispatcher(userSession));
        } catch (error) {
          //If userSession is not valid, clear sessionKey and create a new userSession:
          dispatch(updateSessionKeyAction(null));
          await dispatch(createUserSessionDispatcher());
        }
      }
      //If sessionKey is not present, create a new userSession
      else {
        await dispatch(createUserSessionDispatcher());
      }
      const redirect = getQueryParams()["redirect"];
      if (redirect && redirect !== "/")
        push(
          redirect.startsWith(EXTENSION_SIGN_IN_PATH)
            ? EXTENSION_OPEN_HASH
            : redirect
        );
      dispatch(configureLocalStorageUploadListenerDispatcher());
    }
    // if Cognito session is not active, clear sessionKey:
    else {
      dispatch(updateSessionKeyAction(null));
    }
    dispatch(setBootstrapComplete());
  };
};

export const loadOauthCredentialsDispatcher = () => {
  logDebug("loadOauthCredentialsDispatcher");
  return async (dispatch, getState) => {
    const oauthCredentials = await fetchEntityService.fetchEntity(
      null,
      null,
      EntityType.IdentityProvider,
      null,
      "redeemOauthCredentials"
    );
    Object.keys(oauthCredentials).forEach((key) => {
      localStorage.setItem(key, oauthCredentials[key]);
    });
  };
};

export const checkIdentityProviderLoginDispatcher = () => {
  return async (dispatch, getState) => {
    const { isLoggedIn, hasLoggedIn } = getState().login;
    let { identityProvider } = getState().current.entity;
    if (!isLoggedIn) {
      try {
        const cognitoUser = await getCognitoUser();
        if (!cognitoUser) {
          //Don't pass dispatch/getState in order to avoid processServiceResponse:
          identityProvider = await fetchEntityService.fetchEntity(
            null,
            null,
            EntityType.IdentityProvider
          );
          dispatch(
            currentEntityAction(EntityType.IdentityProvider, identityProvider)
          );
          if (identityProvider.id !== 0) {
            configureAmplify(true);
            if (!hasLoggedIn) {
              await dispatch(oAuthLoginDispatcher());
              return null; //If signing in, keep the login form in loading state.
            }
          }
        }
      } catch (e) {
        //If the identity provider was not found, redirect user to home page:
        logError("Identity provider was not found for user", e);
        if (window) window.location = `https://${oauthHostname}`;
      }
    }
    return identityProvider;
  };
};

export const oAuthLoginDispatcher = () => {
  return async (dispatch, getState) => {
    const { identityProvider } = getState().current.entity;
    try {
      await signinOauth(
        identityProvider.name,
        window?.location.origin,
        window?.location.pathname,
        window?.location.search,
        window?.location.hash
      );
    } catch (e) {
      //If the identity provider was not found, redirect user to home page:
      logError("Identity provider was not found for user", e);
      if (window) window.location = `https://${oauthHostname}`;
    }
  };
};

export const currentContentShareAction = (contentShare) => {
  return {
    type: "CURRENT_CONTENT_SHARE",
    contentShare,
  };
};

export const updateSessionKeyAction = (userSession) => {
  return {
    type: "UPDATE_SESSION_KEY_ACTION",
    userSession,
  };
};

export const setBootstrapComplete = () => {
  return {
    type: "SET_BOOTSTRAP_COMPLETE",
  };
};

export const setIsLoggingIn = (isLoggingIn) => {
  return {
    type: "SET_IS_LOGGING_IN",
    isLoggingIn,
  };
};

export const handleNetworkReconnectionDispatcher = () => {
  return async (dispatch, getState) => {
    logDebug("handleNetworkReconnectionDispatcher");
    const {
      ui,
      current: {
        entity: { userSession },
      },
    } = getState();
    if (userSession) {
      if (!ui.chromeless && !ui.galleryMode) initIntercom(userSession);
      identifyPostHogUser(userSession.user);
      dispatch(fetchCurrentListDispatcher("onlineEntitySocketSessions"));
    }
    
    dispatch(callConnectionStatusObserversAction());
  };
};

export const callConnectionStatusObserversAction = () => {
  return {
    type: "CALL_CONNECTION_STATUS_OBSERVERS_ACTION",
  };
};

export const addConnectionStatusObserverAction = (observer) => {
  return {
    type: "ADD_CONNECTION_STATUS_OBSERVER_ACTION",
    observerId: randomString(10),
    observer,
  };
};

export const removeConnectionStatusObserverAction = (observerId) => {
  return {
    type: "REMOVE_CONNECTION_STATUS_OBSERVER_ACTION",
    observerId,
  };
};

export const isAllowedToShareContent = (
  accountUser,
  content,
  channel,
  account
) => {
  return (
    isContentAdmin(accountUser, content, channel, account) ||
    isChannelAdmin(accountUser, channel, account) ||
    isAccountAdmin(accountUser, account) ||
    (Boolean(accountUser) &&
      Boolean(account) &&
      account.allowSharing &&
      Boolean(content) &&
      content.allowSharing)
  );
};

export const isAllowedToShareChannel = (accountUser, channel, account) => {
  return (
    isChannelAdmin(accountUser, channel, account) ||
    isAccountAdmin(accountUser, account) ||
    (Boolean(accountUser) &&
      Boolean(account) &&
      account.allowSharing &&
      Boolean(channel) &&
      channel.allowSharing)
  );
};

export const isAllowedToSharePlaylist = (accountUser, playlist, account) => {
  return (
    isPlaylistAdmin(accountUser, playlist, account) ||
    isAccountAdmin(accountUser, account) ||
    (Boolean(accountUser) &&
      Boolean(account) &&
      account.allowSharing &&
      Boolean(playlist) &&
      playlist.allowSharing &&
      (!playlist.channel ||
        isAllowedToShareChannel(accountUser, channel, account)))
  );
};

export const isAllowedToUploadToChannel = (accountUser, channel, account) => {
  return (
    isChannelAdmin(accountUser, channel, account) ||
    isAccountAdmin(accountUser, account) ||
    (isAllowedToUpload(accountUser, account) &&
      Boolean(channel) &&
      channel.allowUpload)
  );
};

export const isAllowedToUpload = (accountUser, account) => {
  return (
    (Boolean(accountUser) && Boolean(account) && account.allowUpload) ||
    isAccountAdmin(accountUser, account)
  );
};

export const isAllowedToCreateChannels = (accountUser, account) => {
  return (
    (Boolean(accountUser) && Boolean(account) && account.allowNewChannels) ||
    isAccountAdmin(accountUser, account)
  );
};

export const isAllowedToCreatePlaylists = (accountUser, channel) => {
  return isChannelAdmin(accountUser, channel);
};

export const isAllowedToInviteOthers = (accountUser, account) => {
  return isAccountAdmin(accountUser, account) && Boolean(account);
};

export const isAccountOwner = (accountUser, account) => {
  return (
    Boolean(accountUser) &&
    Boolean(account) &&
    accountUser.id === account.ownerAccountUserId
  );
};

export const isFreeAccount = (account) => {
  return account?.accountType === "FREE";
};

export const isEnterpriseAccount = (account) => {
  return account?.identityProvider?.id > 0;
};

export const isAccountUpgradeRequired = (account, accountUser) => {
  return (
    isAccountAdmin(accountUser, account) &&
    isFreeAccount(account) &&
    account.upgradeRequired
  );
};

export const isAccountAdmin = (accountUser, account) => {
  if (accountUser && account)
    for (var i = 0; i < account.adminAccountUserIds.length; i++)
      if (account.adminAccountUserIds[i] === accountUser.id) return true;
  return false;
};

export const isUserOnline = (user, onlineEntitySocketSessions = []) => {
  if (user) {
    if (user.userOnlineStatus == "Online") return true;
    for (var i = 0; i < onlineEntitySocketSessions.length; i++)
      if (onlineEntitySocketSessions[i].userId === user.id) return true;
  }
  return false;
};

export const isChannelAdmin = (accountUser, channel, account) => {
  return (
    Boolean(accountUser) &&
    Boolean(channel) &&
    (isChannelOwner(accountUser, channel) ||
      isAccountAdmin(accountUser, account))
  );
};

export const isChannelOwner = (accountUser, channel) => {
  return (
    Boolean(accountUser) &&
    Boolean(channel) &&
    accountUser.id === channel.ownerAccountUserId
  );
};

export const isPlaylistAdmin = (accountUser, playlist, account) => {
  return (
    Boolean(accountUser) &&
    Boolean(playlist) &&
    (isChannelOwner(accountUser, playlist.channel) ||
      isPlaylistOwner(accountUser, playlist) ||
      isAccountAdmin(accountUser, account))
  );
};

export const isPlaylistOwner = (accountUser, playlist) => {
  return (
    Boolean(accountUser) &&
    Boolean(playlist) &&
    accountUser.id === playlist.ownerAccountUserId
  );
};

export const isContentAdmin = (accountUser, content, channel, account) => {
  return (
    Boolean(accountUser) &&
    Boolean(content) &&
    (accountUser.id === content.createdByAccountUser.id ||
      accountUser.id === content.ownerAccountUserId ||
      accountUser.id === content.channelOwnerAccountUserId ||
      isAccountAdmin(accountUser, account) ||
      isChannelAdmin(accountUser, channel, account))
  );
};

export const isCommentAdmin = (
  accountUser,
  comment,
  content,
  channel,
  account
) => {
  return (
    Boolean(accountUser) &&
    Boolean(comment) &&
    (accountUser.user.id === comment.createdByUser.id ||
      isContentAdmin(accountUser, content, channel, account))
  );
};

export const isChannelPostAdmin = (accountUser, post, channel, account) => {
  return (
    Boolean(accountUser) &&
    Boolean(post) &&
    (accountUser.id === post.createdByAccountUser.id ||
      isChannelAdmin(accountUser, channel, account))
  );
};

export const isUserProfilePostAdmin = (
  accountUser,
  post,
  userProfile,
  account
) => {
  return (
    Boolean(accountUser) &&
    Boolean(post) &&
    (accountUser.id === post.createdByAccountUser.id ||
      isUserProfileAdmin(accountUser, userProfile, account))
  );
};

export const isChannelSubscriber = (user, channel) => {
  return (
    user && channel && channel.channelSubscriptionUserIds.indexOf(user.id) >= 0
  );
};

export const isPlaylistSubscriber = (user, playlist) => {
  return (
    user &&
    playlist &&
    playlist.playlistSubscriptionUserIds.indexOf(user.id) >= 0
  );
};

export const isAccountUserFollower = (accountUser, userProfile) => {
  if (accountUser && userProfile) {
    for (var i = 0; i < userProfile.activeFollowerAccountUserIds.length; i++)
      if (userProfile.activeFollowerAccountUserIds[i] === accountUser.id)
        return true;
  }
  return false;
};

export const isUserProfileOwner = (accountUser, userProfile) => {
  return (
    Boolean(accountUser) &&
    Boolean(userProfile) &&
    accountUser.id === userProfile.accountUserId
  );
};

export const isUserProfileAdmin = (accountUser, userProfile, account) => {
  return (
    Boolean(accountUser) &&
    Boolean(userProfile) &&
    (isUserProfileOwner(accountUser, userProfile) ||
      isAccountAdmin(accountUser, account))
  );
};

//TODO: Try to move the liked list to the channel instead of at the accountUser level
export const hasLikedContent = (user, content) => {
  if (user && content) {
    for (var i = 0; i < user.likedContentIds.length; i++)
      if (user.likedContentIds[i] === content.id) return true;
  }
  return false;
};

export const hasUnikedContent = (user, content) => {
  if (user && content) {
    for (var i = 0; i < user.unlikedContentIds.length; i++)
      if (user.unlikedContentIds[i] === content.id) return true;
  }
  return false;
};

export const isWatchLaterContent = (user, content) => {
  if (user && content) {
    for (var i = 0; i < user.watchLaterContentIds.length; i++)
      if (user.watchLaterContentIds[i] === content.id) return true;
  }
  return false;
};

export const isAiSummaryEnabled = (user, account) => {
  return account?.summaryEnabled && user?.summaryEnabled;
};
