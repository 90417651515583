import { fetchEntityService } from "../../service/FetchEntityService";
import { fetchListService } from "../../service/FetchListService";
import {
  searchStringAction,
  showModalAction,
  ModalName,
  showMessageBarAction,
  showErrorMessageBarAction,
  showCreateLoginModalDispatcher,
} from "./UIActions";
import {
  sortList,
  entityListKeyMapping,
  ListName,
} from "../reducer/CurrentListActionReducer";
import { createEntityService } from "../../service/CreateEntityService";

import request from "superagent";
import { shareCodesFromQuery } from "../../util/Utils";
import { logError } from "../../service/ServiceUtil";
import { currentContentShareAction } from "./LoginActions";

export const Operation = {
  Create: "CREATE",
  Update: "UPDATE",
  Delete: "DELETE",
};

export const EntityType = {
  AccessRequest: "accessRequest",
  Account: "account",
  AccountInvite: "accountInvite",
  AccountUser: "accountUser",
  AccountUserFollower: "accountUserFollower",
  Assistant: "assistant",
  Board: "Board",
  BoardItem: "BoardItem",
  BoardItemStatus: "BoardItemStatus",
  BotSession: "botSession",
  Caption: "Caption",
  Channel: "channel",
  ChannelPost: "channelPost",
  ChannelShare: "channelShare",
  ChannelShareUser: "channelShareUser",
  ChannelSubscription: "channelSubscription",
  ChapterContentMeta: "chapterContentMeta",
  ChatMessage: "chatMessage",
  ChatUser: "chatUser",
  ChatConversation: "chatConversation",
  ConfluenceSummary: "confluenceSummary",
  Content: "content",
  ContentEvent: "contentEvent",
  ContentShare: "contentShare",
  ContentShareUser: "contentShareUser",
  Comment: "comment",
  Download: "download",
  EntityContentMeta: "entityContentMeta",
  EntitySocketSession: "entitySocketSession",
  FileUpload: "fileUpload",
  GoogleDriveFile: "googleDriveFile",
  GoogleDriveUpload: "googleDriveUpload",
  GoogleMeetBot: "googleMeetBot",
  Keyword: "keyword",
  IdentityProvider: "identityProvider",
  ImportRoutingRule: "importRoutingRule",
  NotificationMessage: "notificationMessage",
  Organization: "organization",
  PasswordReset: "passwordReset",
  Playlist: "playlist",
  PlaylistContent: "playlistContent",
  PlaylistShare: "playlistShare",
  PlaylistShareUser: "playlistShareUser",
  PlaylistSubscription: "playlistSubscription",
  RingCentralRecording: "ringCentralRecording",
  SegmentKeyword: "segmentKeyword",
  ShareRequest: "shareRequest",
  SlackAccountUser: "slackAccountUser",
  SlackChannel: "slackChannel",
  SlackKeywordAlert: "slackKeywordAlert",
  Subscription: "subscription",
  TeamsBot: "teamsBot",
  TeamsRecording: "teamsRecording",
  TranscriptContentMeta: "transcriptContentMeta",
  Upload: "upload",
  User: "user",
  UserContentMeta: "userContentMeta",
  UserOnboarding: "userOnboarding",
  UserProfile: "userProfile",
  UserProfilePost: "userProfilePost",
  UserSession: "userSession",
  WebexRecording: "webexRecording",
  ZoomBot: "zoomBot",
  ZoomLink: "zoomLink",
  ZoomRecording: "zoomRecording",
};

export const currentIdAction = (entityTypeName, id) => {
  return {
    type: "CURRENT_ID_ACTION",
    entityTypeName,
    id,
  };
};

export const currentEntityAction = (entityTypeName, entity) => {
  return {
    type: "CURRENT_ENTITY_ACTION",
    entityTypeName,
    entity,
  };
};

export const clearCurrentStateAction = () => {
  return {
    type: "CLEAR_CURRENT_STATE_ACTION",
  };
};

export const setCurrentPlaytimeAction = (playtime) => {
  return {
    type: "CURRENT_PLAYTIME_ACTION",
    playtime,
  };
};

export const fetchCurrentListDispatcher = (
  listName,
  parentEntityTypeName,
  parentEntityId,
  queryObject = {}
) => {
  return async (dispatch, getState) => {
    const jsonList = await fetchListService.fetchList(
      dispatch,
      getState,
      listName,
      parentEntityTypeName,
      parentEntityId,
      queryObject
    );
    const currentIdState = getState().current.id;
    // Only update currentList is currentEntityId is still the same as the parentId
    // (or no parentEntityTypeName was provided;):
    if (
      !parentEntityTypeName ||
      (currentIdState[parentEntityTypeName + "Id"] &&
        currentIdState[parentEntityTypeName + "Id"] === parentEntityId)
    ) {
      dispatch(currentListAction(listName, jsonList));
    }
    return sortList(jsonList, listName);
  };
};

export const fetchCurrentEntityAction = (entityTypeName, entityId) => {
  return async (dispatch, getState) => {
    const entity = await fetchEntityService.fetchEntity(
      dispatch,
      getState,
      entityTypeName,
      entityId
    );
    dispatch(currentEntityAction(entityTypeName, entity));
    return entity;
  };
};

export const currentIdDispatcher = (entityTypeName, id, queryObj, action) => {
  return async (dispatch, getState) => {
    dispatch(currentIdAction(entityTypeName, id));
    if (id) {
      const entity = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        entityTypeName,
        id,
        action,
        queryObj
      );
      const state = getState();
      if (state.current.id[`${entityTypeName}Id`] === id) {
        dispatch(currentEntityAction(entityTypeName, entity));
      }
      return entity;
    } else {
      dispatch(currentEntityAction(entityTypeName, null));
    }
  };
};

export const currentListAction = (listName, currentList) => {
  return {
    type: "CURRENT_LIST_ACTION",
    listName,
    currentList,
  };
};

export const refreshCurrentEntityDispatcher = (entityTypeName, entityId) => {
  return async (dispatch, getState) => {
    const entity = await fetchEntityService.fetchEntity(
      dispatch,
      getState,
      entityTypeName,
      entityId,
      null,
      shareCodesFromQuery()
    );
    const currentEntity = getState().current.entity[entityTypeName];
    if (currentEntity?.id === entityId)
      dispatch(currentEntityAction(entityTypeName, entity));
    dispatch(
      updateCurrentListItemDispatcher(entityTypeName, entity, Operation.Update)
    );
  };
};

export const updateCurrentListItemDispatcher = (
  entityTypeName,
  item,
  operation
) => {
  return (dispatch, getState) => {
    if (typeof entityListKeyMapping[entityTypeName] !== "undefined") {
      dispatch({
        type: "REMOVE_CURRENT_LIST_ITEM_PARENT_CHANGE_ACTION",
        entityTypeName,
        item,
      });
      const entityLists = entityListKeyMapping[entityTypeName];
      const state = getState();
      for (let listName in entityLists) {
        if (entityLists.hasOwnProperty(listName)) {
          const parentKeyName = entityLists[listName];
          const parentKeyValue = item[parentKeyName];
          // Only CREATE items when the parentKeyName+parentKeyValue is
          // both specified and matches current.id[parentKeyName]
          if (
            operation !== Operation.Create ||
            (parentKeyName &&
              parentKeyValue &&
              state.current.id[parentKeyName] === parentKeyValue)
          ) {
            dispatch(
              updateCurrentListItemAction({
                entityTypeName,
                item,
                operation,
                listName,
              })
            );
          }
        }
      }
    }
  };
};

export const updateCurrentListItemAction = ({
  entityTypeName,
  item,
  operation,
  listName,
}) => {
  return {
    type: "UPDATE_CURRENT_LIST_ITEM_ACTION",
    entityTypeName,
    item,
    operation,
    listName,
  };
};

export const updateCurrentEntityChildItemAction = (entityTypeName, item) => {
  return {
    type: "UPDATE_CURRENT_ENTITY_CHILD_ITEM_ACTION",
    entityTypeName,
    item,
  };
};

export const contentSearchDispatcher = (
  searchString,
  listName,
  parentEntityTypeName,
  parentEntityId
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(searchStringAction(searchString, listName));
      return await dispatch(
        fetchCurrentListDispatcher(
          listName,
          parentEntityTypeName,
          parentEntityId,
          {
            searchString,
            ...shareCodesFromQuery(),
          }
        )
      );
    } catch (e) {
      logError(
        "contentSearchDispatcher",
        { searchString, listName, parentEntityTypeName, parentEntityId },
        e
      );
      throw e;
    }
  };
};

export const graphSearchDispatcher = (
  searchString,
  entityTypeName,
  { includeName, includeSegments, includeKeywords, maxItems = 50 } = {}
) => {
  return async (dispatch, getState) => {
    try {
      if (getState().login.isLoggedIn) {
        dispatch(searchStringAction(searchString, ListName.graphSearchResults));
        return await fetchListService.fetchList(
          dispatch,
          getState,
          `${entityTypeName}/${ListName.graphSearchResults}`,
          null,
          null,
          {
            searchString,
            includeName,
            includeSegments,
            includeKeywords,
            maxItems,
          }
        );
      } else {
        dispatch(showCreateLoginModalDispatcher());
        dispatch(showMessageBarAction(true, "Sign-in for additional features"));
      }
      throw new Error("Not signed in");
    } catch (e) {
      logError("graphSearchDispatcher", { searchString }, e);
      throw e;
    }
  };
};

export const currentContentIdDispatcher = (id, queryObj) => {
  return async (dispatch, getState) => {
    const content = await dispatch(
      currentIdDispatcher(EntityType.Content, id, queryObj)
    );
    if (id)
      await dispatch(
        currentIdDispatcher(EntityType.Account, content.accountId, queryObj)
      );
    return content;
  };
};

export const currentChannelIdDispatcher = (id, queryObj) => {
  return async (dispatch, getState) => {
    try {
      const channel = await dispatch(
        currentIdDispatcher(EntityType.Channel, id, queryObj)
      );
      await dispatch(
        currentIdDispatcher(EntityType.Account, channel.accountId, queryObj)
      );
      return channel;
    } catch (e) {
      //If it's a LoginRequired error, continue showing the channel info:
      if (e.errorCode === "LoginRequired" && e.id) {
        dispatch(currentEntityAction(EntityType.Channel, e));
      }
    }
  };
};

export const currentPlaylistIdDispatcher = (id, queryObj) => {
  return async (dispatch, getState) => {
    try {
      const playlist = await dispatch(
        currentIdDispatcher(EntityType.Playlist, id, queryObj)
      );
      await dispatch(
        currentIdDispatcher(EntityType.Account, playlist.accountId, queryObj)
      );
      return playlist;
    } catch (e) {
      //If it's a LoginRequired error, continue showing the playlist info:
      if (e.errorCode === "LoginRequired" && e.id) {
        dispatch(currentEntityAction(EntityType.Playlist, e));
      }
    }
  };
};

export const currentAccountInviteDispatcher = (queryObj) => {
  return async (dispatch, getState) => {
    try {
      const accountInvite = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.AccountInvite,
        null,
        null,
        queryObj
      );
      dispatch(currentEntityAction(EntityType.AccountInvite, accountInvite));
      dispatch(currentIdAction(EntityType.Account, accountInvite.account.id));
      dispatch(currentEntityAction(EntityType.Account, accountInvite.account));
      return accountInvite;
    } catch (error) {
      if (error.errorCode === "EmailVerificationRequired")
        // Magical generateEmailVerificationRequiredResponse from server:
        dispatch(currentEntityAction(EntityType.User, error));
      throw error;
    }
  };
};

export const currentContentShareDispatcher = (contentId, shareCode) => {
  return async (dispatch, getState) => {
    try {
      const contentShare = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.ContentShare,
        null,
        null,
        { contentId, shareCode }
      );
      dispatch(currentContentShareAction(contentShare));
      return contentShare;
    } catch (error) {
      throw error;
    }
  };
};

export const currentPasswordResetDispatcher = (keyCode) => {
  return (dispatch, getState) => {
    fetchEntityService
      .fetchEntity(dispatch, getState, EntityType.PasswordReset, null, null, {
        keyCode,
      })
      .then((passwordReset) => {
        dispatch(currentEntityAction(EntityType.PasswordReset, passwordReset));
      })
      .catch((e) => {
        window.router.navigate("/forgotPassword");
      });
  };
};

export const currentUserFromEmailLinkDispatcher = (emailVerifyCode) => {
  return (dispatch, getState) => {
    let formObject = {
      emailVerifyCode,
    };
    createEntityService
      .createEntity(
        dispatch,
        getState,
        "user/verifyEmailLink",
        null,
        formObject
      )
      .then((user) => {
        dispatch(currentEntityAction(EntityType.User, user));
        dispatch(showErrorMessageBarAction(false));
      })
      .catch((e) => {});
  };
};

export const currentUserFromEmailPinDispatcher = (
  emailAddress,
  emailVerifyPin
) => {
  return async (dispatch, getState) => {
    let formObject = {
      emailAddress,
      emailVerifyPin,
    };
    try {
      const user = await createEntityService.createEntity(
        dispatch,
        getState,
        "user/verifyEmailPin",
        null,
        formObject
      );
      user.emailVerifyPin = emailVerifyPin;
      dispatch(currentEntityAction(EntityType.User, user));
      dispatch(showErrorMessageBarAction(false));
      return user;
    } catch (e) {
      throw e;
    }
  };
};

export const currentContentMetaDispatcher = (content, contentMetaType) => {
  return (dispatch, getState) => {
    if (contentMetaType === EntityType.EntityContentMeta) {
      dispatch(
        currentIdDispatcher(contentMetaType, content.id, {
          version: content.entityContentMetaVersion,
          ...shareCodesFromQuery(),
        })
      );
    } else {
      dispatch(currentIdAction(contentMetaType, content.id));
      const url =
        contentMetaType === EntityType.EntityContentMeta
          ? content.entityContentMetaUrl
          : contentMetaType === EntityType.TranscriptContentMeta
          ? content.transcriptContentMetaUrl
          : content.chapterContentMetaUrl;
      url &&
        request
          .get(url)
          .set("Accept", "application/json")
          .end((error, response) => {
            const contentMetaData = response?.ok && response?.body;
            if (contentMetaData) {
              dispatch(currentEntityAction(contentMetaType, contentMetaData));
            }
          });
    }
  };
};

export const fetchKeywordTagCloudDispatcher = (
  parentEntityType,
  parentEntityId,
  sinceDateISOString
) => {
  return async (dispatch, getState) => {
    dispatch(currentListAction(ListName.keywordTagCloud, null));
    const keywordTagCloud = await fetchListService.fetchList(
      dispatch,
      getState,
      ListName.keywordTagCloud,
      parentEntityType,
      parentEntityId,
      { sinceDateISOString }
    );
    dispatch(currentListAction(ListName.keywordTagCloud, keywordTagCloud));
  };
};

export const currentDownloadDispatcher = (contentId) => {
  return async (dispatch, getState) => {
    if (contentId) {
      const download = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.Content,
        contentId,
        EntityType.Download,
        shareCodesFromQuery()
      );
      dispatch(currentIdAction(EntityType.Download, download.id));
      dispatch(currentEntityAction(EntityType.Download, download));
      return download;
    } else {
      dispatch(currentEntityAction(EntityType.Download, null));
    }
  };
};

export const fetchUserOnboardingAction = () => {
  return async (dispatch, getState) => {
    const entity = await fetchEntityService.fetchEntity(
      dispatch,
      getState,
      EntityType.UserOnboarding
    );
    dispatch(currentEntityAction(EntityType.UserOnboarding, entity));
    return entity;
  };
};

export const fetchChatMessageContentListAction = (contentId) => {
  return async (dispatch, getState) => {
    const contentList = contentId
      ? [
          await fetchEntityService.fetchEntity(
            dispatch,
            getState,
            EntityType.Content,
            contentId
          ),
        ]
      : [];
    dispatch(currentListAction(ListName.chatMessageContent, contentList));
    return contentList;
  };
};
