import React from "react";
import SiteHead from "../component/head/SiteHead";
import { EntityType } from "../redux/action/CurrentActions";
import { getIsChromeless } from "../redux/action/UIActions";
import { logDebug, logError } from "../service/ServiceUtil";
import { useCookie } from "next-cookie";

const Home = ({ content, channel, requestUrl }) => {
  return (
    <div suppressHydrationWarning={true}>
      <SiteHead content={content} channel={channel} requestUrl={requestUrl} />
    </div>
  );
};

export default Home;

const supportedPermissionsList = [
  "camera",
  "microphone",
  "fullscreen",
  "autoplay",
  "clipboard-read",
  "clipboard-write",
  "speaker",
  "speaker-selection",
  "web-share",
  "display-capture",
  "screen-wake-lock",
  "payment",
  "document-domain",
  "identity-credentials-get",
  "idle-detection",
  "local-fonts",
  "otp-credentials",
  "picture-in-picture",
  "publickey-credentials-create",
  "publickey-credentials-get",
  "serial",
  "storage-access",
  "usb",
  "window-management",
];
const unsupportedPermissionsList = [
  "accelerometer",
  "ambient-light-sensor",
  "attribution-reporting",
  "bluetooth",
  "browsing-topics",
  "compute-pressure",
  "encrypted-media",
  "gamepad",
  "geolocation",
  "gyroscope",
  "magnetometer",
  "midi",
  "xr-spatial-tracking",
];
const getPermissionsHeaderValue = () => {
  const supportedPermissions = supportedPermissionsList.join("=*, ") + "=*, ";
  const unsupportedPermissions =
    unsupportedPermissionsList.join("=(), ") + "=()";
  return `${supportedPermissions} ${unsupportedPermissions}`;
};

const getQueryObject = (req, query) => {
  if (query.redirectUrl) {
    const url = new URL(`https://${req.headers.host}${query.redirectUrl}`);
    const params = new URLSearchParams(url.search);

    const queryObject = {};
    params.forEach((value, key) => {
      queryObject[key] = value;
    });
    return queryObject;
  } else return query;
};

const getCSPDomainList = (query, share) => {
  const list = [];
  if (share && getIsChromeless(query)) {
    list.push("*"); //TODO: For Slack, remove when resolved
    list.push("'self'");
    list.push("https://*.pixelmixer.com");
    list.push("https://*.slack.com");
    list.push("https://*.okta.com");
  } else {
    list.push("*"); //TODO: For Slack, remove when resolved
    list.push("'self'");
    list.push("https://*.pixelmixer.com");
    list.push("https://*.slack.com");
  }
  if (share?.cspDomain) list.push(`https://*.${share.cspDomain}`);
  return list;
};

export const getServerSideProps = async (context) => {
  const { req, res, query } = context;
  const cookies = useCookie(context);
  // setOauthCredentialsCookie(cookies);
  res.setHeader(
    "Cache-Control",
    "private, no-cache, no-store, must-revalidate"
  );
  res.setHeader("Permissions-Policy", getPermissionsHeaderValue());
  //Set via cloudfront response header policy. no need to do here:
  // res.setHeader(
  //   "Content-Security-Policy",
  //   "frame-ancestors 'self' *.pixelmixer.com"
  // );
  // res.setHeader("X-Robots-Tag", "noindex, nofollow");

  const contentAndChannelShareObject =
    (await getContentAndChannelShares(req)) || {};
  const { channelShare, contentShare } = contentAndChannelShareObject;
  //TODO: Find out the slack embed domain and remove *
  // if (channelShare || contentShare)
  res.setHeader(
    "Content-Security-Policy",
    `frame-ancestors ${getCSPDomainList(
      query,
      channelShare || contentShare
    ).join(" ")}`
  );
  return {
    props: {
      time: new Date().toISOString(),
      ...contentAndChannelShareObject,
    },
  };
};

const getContentAndChannelShares = async (req) => {
  try {
    const shareCodesObj = getShareCodesObject(req);
    const baseUrl = `https://${req.headers.host}`;
    const requestUrl = baseUrl + req.url;

    if (shareCodesObj) {
      const urlPathList = req.url
        .substring(
          1,
          req.url.includes("?") ? req.url.indexOf("?") : req.url.length
        )
        .split("/");
      if (urlPathList[0] === "video" || urlPathList[0] === "shares") {
        const contentShare = await fetchEntity(
          EntityType.ContentShare,
          null,
          shareCodesObj
        );
        return {
          contentShare,
          content: contentShare.content,
          requestUrl,
        };
      } else if (urlPathList[0] === "channel") {
        if (urlPathList.length === 4) {
          const channelShare = await fetchEntity(
            EntityType.ChannelShare,
            null,
            shareCodesObj
          );
          const content = await fetchEntity(EntityType.Content, urlPathList[3]);
          return {
            channelShare,
            content,
            requestUrl,
          };
        } else {
          const channelShare = await fetchEntity(
            EntityType.ChannelShare,
            null,
            shareCodesObj
          );
          return {
            channelShare,
            channel: channelShare.channel,
            requestUrl,
          };
        }
      }
    }
  } catch (e) {
    logError("getContentAndChannelShares", e);
  }
};

const getShareCodesObject = (req) => {
  const shareCode = getQueryParam(req, "shareCode");
  const channelShareCode = getQueryParam(req, "channelShareCode");
  if (shareCode) return { shareCode };
  else if (channelShareCode) return { channelShareCode };
};

const getQueryParam = (req, queryParam) => {
  const url = req.url;
  const params =
    url.indexOf("?") > 0
      ? new URLSearchParams(url.substring(url.indexOf("?"), url.length))
      : null;
  if (params) return params.get(queryParam);
};

const fetchEntity = async (entityType, entityId, shareCodesObj) => {
  const url = new URL(
    `${process.env.CORE_BASE_URL}/metaService/${entityType}${
      entityId ? "/" + entityId : ""
    }`
  );
  if (shareCodesObj) {
    const searchParams = new URLSearchParams(shareCodesObj);
    url.search = searchParams.toString();
  }
  const response = await fetch(url, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(`fetchShare error! status: ${response.status}`);
  }

  return await response.json();
};

//Transfer the cookies because othwerwise Chrome will block it as a cross-site,
//particularly in embedded iFrame envs like Confluence.
export const setOauthCredentialsCookie = async (cookies) => {
  logDebug("setOauthCredentialsCookie");
  const COOKIE_NAME = "__Secure-credentialsHash";
  try {
    const credentialsHash = cookies.get(COOKIE_NAME) || null;
    if (credentialsHash) {
      cookies.set(COOKIE_NAME, credentialsHash, {
        path: "/",
        httpOnly: true,
        secure: true,
        maxAge: 60 * 60, // 1 hour
        sameSite: "strict",
      });
    }
  } catch (e) {
    logError("setOauthCredentialsCookie", e);
  }
};
