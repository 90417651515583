import { createEntityService } from "../../service/CreateEntityService";
import {
  queueFileUploadAction,
  fileUploadProcessingDispatcher,
  updateUploadAction,
} from "./UploadQueueActions";
import {
  isAccountUpgradeRequired,
  isFreeAccount,
  setIsLoggingIn,
} from "./LoginActions";
import {
  showMessageBarAction,
  showErrorMessageBarAction,
  showModalAction,
  ModalName,
  showCreateLoginModalDispatcher,
} from "./UIActions";
import { refreshCurrentUserSessionStateDispatcher } from "./LoginActions";
import {
  currentEntityAction,
  updateCurrentListItemDispatcher,
  Operation,
  EntityType,
  currentIdAction,
} from "./CurrentActions";
import queue from "queue";
import {
  trackIntercomEvent,
  isTestEnv,
  getIsTestAgent,
  cleanPhoneNumber,
  getQueryParamsFromPath,
  shareCodesFromQuery,
  getQueryParams,
  push,
} from "../../util/Utils";
import { fetchEntityService } from "../../service/FetchEntityService";
import { updateEntityService } from "../../service/UpdateEntityService";
import request from "superagent";
import posthog from 'posthog-js'
import { logError, logInfo } from "../../service/ServiceUtil";

export const ShareType = {
  ACCESS_REQUEST: "access request",
  APP: "App",
  EMAIL: "email",
  EMBED: "Embedded HTML Player",
  SLACK_EMBED: "Slack Update",
  PORTAL: "Embedded player / macro",
  LINK: "copied link",
  SHARE_REQUEST: "share request",
  SMS: "SMS",
  USER_EMAIL: "user email",
  USER_SMS: "user SMS",
  USER_WHATS_APP: "user WhatsApp",
  WHATS_APP: "WhatsApp",
};

export const ContactType = {
  email: "email",
  sms: "SMS",
  whatsApp: "WhatsApp",
};

export const PlaylistType = {
  channel: "channel",
  userProfile: "userProfile",
};

export const RecordingImportStatus = {
  NONE: "",
  IMPORT: "In progress",
  ERROR: "Error",
  SUCCESS: "On-line",
};

export const BotSessionStatus = {
  Scheduled: "Scheduled",
  InProcess: "In Progress",
  Importing: "Importing",
  Complete: "Complete",
  Cancelled: "Cancelled",
  Error: "Error",
};

export const GettingStartedStep = {
  Record: "Record",
  Share: "Share",
  Channels: "Channels",
  Integrations: "Integrations",
  Done: "Done",
};

export const ContentEventType = {
  CREATED: "CREATED",
  BEGIN: "BEGIN",
  START: "START",
  REWIND: "REWIND",
  FORWARD: "FORWARD",
  PAUSE: "PAUSE",
  STOP: "STOP",
  WATCH: "WATCH",
  COMMENT: "COMMENT",
  FINISH: "FINISH",
  LIKED: "LIKED",
  FLAGGED: "FLAGGED",
  SHARED: "SHARED",
  UNLIKED: "UNLIKED",
  FULLSCREEN: "FULLSCREEN",
  WATCH_LATER: "WATCH_LATER",
};

export const createAccountDispatcher = (formObj) => {
  return async (dispatch, getState) => {
    try {
      const account = await createEntityService.createEntity(
        dispatch,
        getState,
        "account",
        {},
        formObj
      );
      dispatch(currentEntityAction(EntityType.Account, account));
    } catch (error) {
      //This logic is to allow the user to stay in the create account flow,
      //incase they are trying again:
      if (error.errorCode === "AlreadyExists") {
        dispatch(currentEntityAction(EntityType.Account, error));
        return error;
      } else if (error.errorCode === "NotSignedIn") {
        window.router.navigate("/login");
      }
      throw error;
    }
  };
};

/**
 *
 * @param {*} googleDriveFolderId
 * @param {*} contentId
 * @param {*} accessKey
 * @returns
 */
export const createGoogleDriveUploadDispatcher = (
  googleDriveFolderId,
  contentId,
  accessToken
) => {
  return async (dispatch, getState) => {
    const formObject = {
      googleDriveFolderId,
      contentId,
      accessToken,
    };
    const googleDriveUpload = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.GoogleDriveUpload,
      {},
      formObject
    );
    return googleDriveUpload;
  };
};

/**
 *
 * @param {object} file - for sessions uploads, include {name, type, size, sessionId}
 * @param {*} channelId
 * @param {*} trimStart
 * @param {*} trimEnd
 * @param {*} sessionId
 * @returns
 */
export const createUploadDispatcher = (
  file,
  { channelId, trimStart, trimEnd, isPrivate, contentId, name, posterUrl }
) => {
  return async (dispatch, getState) => {
    const account = getState().current.entity.account;
    const accountUser = getState().current.entity.userSession.accountUser;
    if (isAccountUpgradeRequired(account, accountUser)) {
      window.router.navigate("/account/billing");
      throw Error("Account upgrade required");
    }
    const deviceAPI = getState().ui.deviceAPI;
    name = name
      ? name
      : deviceAPI.isMobile
      ? `Uploaded from my ${deviceAPI.deviceName}`
      : undefined;
    const formObject = {
      filename: file.name,
      name,
      channelId,
      contentId,
      contentType: file.type,
      crop: file.crop,
      trimStart,
      trimEnd,
      isPrivate,
      uploadSessionId: file.recorderOptions?.uploadSessionId || null,
    };
    if (isFreeAccount(account) && file.size > 500 * Math.pow(1024, 2)) {
      dispatch(showMessageBarAction(true, "Upgrade to upload big files"));
      window.router.navigate("/account/billing");
      trackIntercomEvent("file upload limit exceeded", {
        ...formObject,
        size: file.size,
      });
      throw { message: "Video too big", errorCode: "tooBig" };
    } else if (file.size > 20 * Math.pow(1024, 3)) {
      dispatch(
        showMessageBarAction(true, "Contact support for large file support")
      );
      trackIntercomEvent("file upload limit exceeded", {
        ...formObject,
        size: file.size,
      });
      throw { message: "Video too big", errorCode: "tooBig" };
    }
    const upload = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.Upload,
      {},
      formObject
    );
    dispatch(queueFileUploadAction(file, upload, posterUrl));
    dispatch(fileUploadProcessingDispatcher());
    trackEvent("uploadVideo", { contentId: upload.contentId });
    trackIntercomEvent("upload video", formObject);
    dispatch(
      createContentEventDispatcher(upload.contentId, ContentEventType.CREATED)
    );
    return upload;
  };
};

export const createCommentDispatcher = (
  contentId,
  message,
  playtime,
  parentCommentId,
  shareCodes
) => {
  return async (dispatch, getState) => {
    const { isLoggedIn } = getState().login;
    const { account } = getState().current.entity;
    const requireLogin = !!account ? account.requireLogin : true;
    if (!isLoggedIn && requireLogin) {
      dispatch(showCreateLoginModalDispatcher());
      dispatch(showMessageBarAction(true, "login to comment"));
      throw new Error("Not signed in");
    }
    const queryObject = {
      contentId,
      playtime,
      parentCommentId,
      ...shareCodes,
    };
    const formObject = {
      message,
    };
    const comment = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.Comment,
      queryObject,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Comment,
        comment,
        Operation.Create
      )
    );
    dispatch(showMessageBarAction(true, "comment posted!"));
    trackEvent("videoComment", { contentId: comment.contentId });
    trackIntercomEvent("post comment", { contentId, message });
    dispatch(
      createContentEventDispatcher(
        contentId,
        ContentEventType.COMMENT,
        playtime,
        window?.location.pathname
      )
    );
    return comment;
  };
};

export const createPostCommentDispatcher = (
  postId,
  message,
  parentCommentId,
  shareCodes
) => {
  return async (dispatch, getState) => {
    const queryObject = {
      parentCommentId,
      ...shareCodes,
    };
    const formObject = {
      message,
    };
    const comment = await createEntityService.createEntity(
      dispatch,
      getState,
      `post/${postId}/${EntityType.Comment}`,
      queryObject,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.Comment,
        comment,
        Operation.Create
      )
    );
    dispatch(showMessageBarAction(true, "comment posted!"));
    trackEvent("postComment", { postId });
    trackIntercomEvent("post comment", { postId, message });
    return comment;
  };
};

export const createPostDispatcher = (
  message,
  parentEntityType,
  parentEntityId,
  shareCodes
) => {
  return async (dispatch, getState) => {
    const queryObject = {
      ...shareCodes,
    };
    const formObject = {
      message,
    };
    const post = await createEntityService.createEntity(
      dispatch,
      getState,
      `${parentEntityType}/${parentEntityId}/post`,
      queryObject,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      updateCurrentListItemDispatcher(
        parentEntityType === EntityType.Channel
          ? EntityType.ChannelPost
          : EntityType.UserProfilePost,
        post,
        Operation.Create
      )
    );
    dispatch(showMessageBarAction(true, "message posted!"));
    trackEvent(parentEntityType + " post", { parentEntityId });
    trackIntercomEvent("post to " + parentEntityType, {
      parentEntityId,
      message,
    });
    return post;
  };
};

export const createUserSessionDispatcher = (referralSource) => {
  return async (dispatch, getState) => {
    try {
      const userSession = await createEntityService.createEntity(
        dispatch,
        getState,
        EntityType.UserSession,
        {},
        {
          ...shareCodesFromQuery(),
          referralSource,
        }
      );
      if (userSession.isNewAccount) {
        trackEvent("createAccount");
        trackConversion();
      }

      dispatch(showErrorMessageBarAction(false));
      dispatch(showModalAction(ModalName.OauthLoginModal, false));
      await dispatch(refreshCurrentUserSessionStateDispatcher(userSession));
      dispatch(setIsLoggingIn(false));
      const redirect = userSession.user.redirect;
      if (redirect && redirect !== "/") {
        logInfo("userSession redirect", { redirect });
        push(redirect);
      }
      return userSession;
    } catch (e) {
      dispatch(setIsLoggingIn(false));
      throw e;
    }
  };
};

export const createUserDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const { search = "", pathname = "" } = window.location;
    const redirect =
      !pathname.startsWith("/createLogin") && !pathname.startsWith("/login")
        ? pathname + search
        : null;
    const user = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.User,
      {},
      { ...formObject, redirect }
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.User, user));
    dispatch(showModalAction(ModalName.VerifyEmailPinModal, true));
    trackEvent("createAccountUser", { accountUserId: user.id });
    return user;
  };
};

export const createChannelDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const channel = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.Channel,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    window.router.navigate(`/channel/${channel.id}`);
    trackIntercomEvent("create channel", formObject);
  };
};

export const createChatConversationDispatcher = ({ userIdList = [] }) => {
  return async (dispatch, getState) => {
    const chatConversation = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ChatConversation,
      {},
      { userIdList }
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.ChatConversation,
        chatConversation,
        Operation.Create
      )
    );
    dispatch(showErrorMessageBarAction(false));
    trackIntercomEvent("create chat conversation", { userIdList });
    return chatConversation;
  };
};

export const createChatMessageDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const chatMessage = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ChatMessage,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.ChatMessage,
        chatMessage,
        Operation.Create
      )
    );
    trackEvent("chatMessage", { toUserId: chatMessage.toUserId });
    trackIntercomEvent("sent message", formObject);
    return chatMessage;
  };
};

export const createChannelSubscriptionDispatcher = (channelId) => {
  return async (dispatch, getState) => {
    const formObject = {
      channelId,
    };
    const channelSubscription = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ChannelSubscription,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `You are now subscribed to ${channelSubscription.channel.name}`
      )
    );
    trackEvent("channelSubscription", { channelId });
    trackIntercomEvent("subscribed to channel", formObject);
    return channelSubscription;
  };
};

export const createPlaylistSubscriptionDispatcher = (playlistId) => {
  return async (dispatch, getState) => {
    const formObject = {
      playlistId,
    };
    const playlistSubscription = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.PlaylistSubscription,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `You are now subscribed to ${playlistSubscription.playlist.name}`
      )
    );
    trackEvent("playlistSubscription", { playlistId });
    trackIntercomEvent("subscribed to playlist", formObject);
    return playlistSubscription;
  };
};

export const createAccountUserFollowerDispatcher = (userProfileId) => {
  return async (dispatch, getState) => {
    const formObject = {
      userProfileId,
    };
    const accountUserFollower = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.AccountUserFollower,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `You are now following to ${accountUserFollower.fullName}`
      )
    );
    trackEvent("accountUserFollower", { userProfileId });
    trackIntercomEvent("followed user", formObject);
    return accountUserFollower;
  };
};

export const verifyPhoneDispatcher = (userId, phoneVerifyPin) => {
  return async (dispatch, getState) => {
    const formObject = {
      userId,
      phoneVerifyPin,
    };
    const user = await createEntityService.createEntity(
      dispatch,
      getState,
      "user/verifyPhone",
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.User, user));
    return user;
  };
};

export const sendPhoneVerificationDispatcher = (
  userId,
  emailVerifyCode,
  phone
) => {
  return (dispatch, getState) => {
    let formObject = {
      userId,
      emailVerifyCode,
      phone,
    };
    createEntityService
      .createEntity(
        dispatch,
        getState,
        "user/sendPhoneVerificationPin",
        {},
        formObject
      )
      .then((jsonObject) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(currentEntityAction(EntityType.User, jsonObject));
      })
      .catch((e) => {});
  };
};

export const createAccountInviteDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const accountInvite = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.AccountInvite,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `Invite sent to ${accountInvite.emailAddress || accountInvite.phone}`
      )
    );
    trackEvent("inviteUser");
    trackIntercomEvent("sent invite", formObject);
  };
};

export const createAccessRequestDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    try {
      const accessRequest = await createEntityService.createEntity(
        dispatch,
        getState,
        EntityType.AccessRequest,
        {},
        formObject
      );
      dispatch(showErrorMessageBarAction(false));
      dispatch(showModalAction(ModalName.RequestAccessModal, false));
      dispatch(
        showMessageBarAction(
          true,
          `Sent! You will receive an email once accepted`
        )
      );
      trackIntercomEvent("requested access", formObject);
      return accessRequest;
    } catch (e) {
      logError("createAccessRequestDispatcher", formObject, e);
      if (!e.accessRequestId) throw e;
    }
  };
};

export const sendAccountInviteRequestDispatcher = (inviteCode) => {
  return async (dispatch, getState) => {
    const queryObject = {
      inviteCode,
    };
    const accountInviteRequest = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.AccountInvite + "/sendAccountInviteRequest",
      queryObject,
      {}
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `Request sent to ${accountInviteRequest.createdByAccountUser.fullName}`
      )
    );
    trackIntercomEvent("requested invite");
    return accountInviteRequest;
  };
};

export const createContentShareDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const contentShare = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ContentShare,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.ContentShare, contentShare));
    trackEvent("shareVideoLink", { contentId: contentShare.contentId });
    trackIntercomEvent("copied share link", formObject);
    dispatch(
      createContentEventDispatcher(
        formObject.contentId,
        ContentEventType.SHARED,
        formObject.playtime,
        window?.location.pathname
      )
    );
    return contentShare;
  };
};

export const createContentShareUserDispatcher = (formObject) => {
  const { contentId, sendEmail, emailAddress, phone } = formObject;
  return async (dispatch, getState) => {
    const contentShareUser = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ContentShareUser,
      {},
      { ...formObject, phone: cleanPhoneNumber(phone) }
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `Link sent to ${sendEmail ? emailAddress : phone}`
      )
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.ContentShareUser,
        contentShareUser,
        Operation.Create
      )
    );
    trackEvent("shareVideo", { contentId: contentId });
    trackIntercomEvent("sent share link", formObject);
    dispatch(
      createContentEventDispatcher(
        formObject.contentId,
        ContentEventType.SHARED,
        formObject.playtime,
        window?.location.pathname
      )
    );
  };
};

export const createChannelShareDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const channelShare = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ChannelShare,
      {},
      formObject
    );
    dispatch(currentEntityAction(EntityType.ChannelShare, channelShare));
    dispatch(showErrorMessageBarAction(false));
    trackEvent("shareChannelLink", { channelId: channelShare.channelId });
    trackIntercomEvent("copied channel share link", formObject);
    return channelShare;
  };
};

export const createChannelShareUserDispatcher = (formObject) => {
  const { channelId, sendEmail, emailAddress, phone } = formObject;
  return async (dispatch, getState) => {
    const channelShareUser = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.ChannelShareUser,
      {},
      { ...formObject, phone: cleanPhoneNumber(phone) }
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `Link sent to ${sendEmail ? emailAddress : phone}`
      )
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.ChannelShareUser,
        channelShareUser,
        Operation.Create
      )
    );
    trackEvent("shareChannel", { channelId });
    trackIntercomEvent("sent channel share link", formObject);
  };
};

export const createPlaylistShareDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const playlistShare = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.PlaylistShare,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.PlaylistShare, playlistShare));
    trackEvent("shareVideoLink", { playlistId: playlistShare.playlistId });
    trackIntercomEvent("copied share link", formObject);
    return playlistShare;
  };
};

export const createPlaylistShareUserDispatcher = (formObject) => {
  const { playlistId, sendEmail, emailAddress, phone } = formObject;
  return async (dispatch, getState) => {
    const playlistShareUser = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.PlaylistShareUser,
      {},
      { ...formObject, phone: cleanPhoneNumber(phone) }
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(
      showMessageBarAction(
        true,
        `Link sent to ${sendEmail ? emailAddress : phone}`
      )
    );
    dispatch(
      updateCurrentListItemDispatcher(
        EntityType.PlaylistShareUser,
        playlistShareUser,
        Operation.Create
      )
    );
    trackEvent("shareVideo", { playlistId });
    trackIntercomEvent("sent share link", formObject);
  };
};

export const sendContentShareRequestDispatcher = (formObject) => {
  return (dispatch, getState) => {
    createEntityService
      .createEntity(
        dispatch,
        getState,
        EntityType.ContentShare + "/sendShareRequest",
        {},
        formObject
      )
      .then((jsonObject) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Request sent`));
        trackIntercomEvent("requested share", formObject);
      })
      .catch((e) => {});
    dispatch(showModalAction(ModalName.RequestShareModal, false));
  };
};

export const sendChannelShareRequestDispatcher = (formObject) => {
  return (dispatch, getState) => {
    createEntityService
      .createEntity(
        dispatch,
        getState,
        EntityType.ChannelShare + "/sendShareRequest",
        {},
        formObject
      )
      .then((jsonObject) => {
        dispatch(showErrorMessageBarAction(false));
        dispatch(showMessageBarAction(true, `Request sent`));
        trackIntercomEvent("requested channel share", formObject);
      })
      .catch((e) => {});
    dispatch(showModalAction(ModalName.RequestShareModal, false));
  };
};

export const resendAccountInviteDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const accountInvite = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.AccountInvite + "/resendAccountInvite",
      null,
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    dispatch(currentEntityAction(EntityType.AccountInvite, accountInvite));
    dispatch(
      showMessageBarAction(true, `Invite sent to ${accountInvite.emailAddress}`)
    );
    trackIntercomEvent("resent invite", formObject);
    return accountInvite;
  };
};

export const sendPasswordResetLinkDispatcher = (emailAddress) => {
  return async (dispatch, getState) => {
    let formObject = {
      emailAddress,
    };
    try {
      const passwordReset = await createEntityService.createEntity(
        dispatch,
        getState,
        "passwordReset/sendResetLink",
        {},
        formObject
      );
      dispatch(
        showErrorMessageBarAction(true, "Check email to reset password")
      );
      trackIntercomEvent("sent password reset", formObject);
      return passwordReset;
    } catch (e) {
      throw e;
    }
  };
};

const contentEventQueue = queue();
contentEventQueue.concurrency = 1;
contentEventQueue.autostart = true;
var lastContentEventTime = {
  BEGIN: 0,
  START: 0,
  PLAY: 0,
  REWIND: 0,
  FORWARD: 0,
  PAUSE: 0,
  STOP: 0,
  WATCH: 0,
  FINISH: 0,
  LIKED: 0,
};
export const createContentEventDispatcher = (
  contentId,
  contentEventType,
  playtime = 0,
  urlPath,
  shareCodes = {}
) => {
  const endpointUrl = "/api/gw/pixelmixer-content-event-queue-service";
  return (dispatch, getState) => {
    let timeNow = new Date();
    var soonestTime = new Date();
    soonestTime.setSeconds(timeNow.getSeconds() - 1);
    if (
      !lastContentEventTime[contentEventType] ||
      lastContentEventTime[contentEventType] < soonestTime
    ) {
      lastContentEventTime[contentEventType] = timeNow;
      const {
        deviceAPI: { browserName, deviceName, deviceType },
      } = getState().ui;
      const formObject = {
        contentId,
        contentEventType,
        playtime,
        createdDate: timeNow.getTime(),
        urlPath,
        browserName,
        deviceName,
        deviceType,
        xSessionKey: getState().login.sessionKey,
        sessionId:
          window.DD_RUM?.getInternalContext()?.session_id ||
          getState().login.sessionKey,
        ...shareCodes,
      };
      contentEventQueue.push((cb) => {
        request
          .post(endpointUrl)
          .set("Accept", "text/plain")
          .type("text/plain")
          .send(JSON.stringify(formObject))
          .end((error, response) => {
            if (error) logError("createContentEventDispatcher", cb, error);
            else if (!response.ok)
              logError("createContentEventDispatcher", formObject);
            cb();
          });
      });
    }
  };
};

//TODO: Also check the playtime and don't allow approximate likes atleast for the current session.
var lastLike = new Date();
export const createContentLikeDispatcher = (
  shareCodes,
  contentId,
  playtime
) => {
  return async (dispatch, getState) => {
    let timeNow = new Date();
    var soonestTime = new Date();
    soonestTime.setSeconds(timeNow.getSeconds() - 3);
    if (lastLike < soonestTime) {
      const userContentMeta = await updateEntityService.updateEntity(
        dispatch,
        getState,
        EntityType.UserContentMeta,
        contentId,
        { playtime },
        "like",
        shareCodes
      );
      dispatch(
        showMessageBarAction(true, `liked ${userContentMeta.content.name}`)
      );
      trackEvent("likeVideo", { contentId: contentId });
      trackIntercomEvent("liked", {
        name: userContentMeta.content.name,
        playtime,
      });
    } else dispatch(showErrorMessageBarAction(true, "liking too much!"));
    lastLike = new Date();
    dispatch(
      createContentEventDispatcher(
        contentId,
        ContentEventType.LIKED,
        playtime,
        window?.location.pathname
      )
    );
  };
};

export const createCommentLikeDispatcher = (commentId) => {
  return async (dispatch, getState) => {
    let timeNow = new Date();
    var soonestTime = new Date();
    soonestTime.setSeconds(timeNow.getSeconds() - 3);
    if (lastLike < soonestTime) {
      const comment = await updateEntityService.updateEntity(
        dispatch,
        getState,
        EntityType.Comment,
        commentId,
        null,
        "like"
      );
      dispatch(
        showMessageBarAction(
          true,
          `liked comment from ${comment.createdByUser.fullName}`
        )
      );
      dispatch(
        updateCurrentListItemDispatcher(
          EntityType.Comment,
          comment,
          Operation.Update
        )
      );
      trackEvent("likeComment", { commentId });
      trackIntercomEvent("likedComment", {
        contentId: comment.contentId,
        playtime: comment.playtime,
        commentId,
      });
    } else dispatch(showErrorMessageBarAction(true, "liking too much!"));
    lastLike = new Date();
  };
};

export const createPostLikeDispatcher = (postId) => {
  return async (dispatch, getState) => {
    let timeNow = new Date();
    var soonestTime = new Date();
    soonestTime.setSeconds(timeNow.getSeconds() - 3);
    if (lastLike < soonestTime) {
      const post = await updateEntityService.updateEntity(
        dispatch,
        getState,
        "post",
        postId,
        null,
        "like"
      );
      dispatch(
        showMessageBarAction(
          true,
          `liked post from ${post.createdByAccountUser.fullName}`
        )
      );
      dispatch(
        updateCurrentListItemDispatcher(
          post.postType === "channelPost"
            ? EntityType.ChannelPost
            : EntityType.UserProfilePost,
          post,
          Operation.Update
        )
      );
      trackEvent("likedPost", { postId });
      trackIntercomEvent("likedPost", {
        postId,
      });
    } else dispatch(showErrorMessageBarAction(true, "liking too much!"));
    lastLike = new Date();
  };
};

export const watchingContentDispatcher = (shareCodes, contentId) => {
  return (dispatch, getState) => {
    updateEntityService
      .updateEntity(
        dispatch,
        getState,
        EntityType.UserContentMeta,
        contentId,
        {},
        "watched",
        shareCodes
      )
      .then((userContentMeta) => {
        trackEvent("viewVideo", { contentId: contentId });
        trackIntercomEvent("watching", {
          name: userContentMeta.content.name,
          contentId,
        });
      })
      .catch((e) => {});
  };
};

let isPostHogLoaded = false;
export const trackEvent = (eventName, attrObj = {}) => {
  const initPostHog = () => {
    posthog.init("phc_BhPWXnZCbzMNYeRIgZ4rfqYs7XeZLXAKWby2trDxQMb", {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only", 
    });
    isPostHogLoaded = true;
  };
  try {
    if (!getIsTestAgent()) {
      if (!isPostHogLoaded) initPostHog();
      posthog.capture(eventName, attrObj);
    }
  } catch (e) {
    logError("trackEvent", { eventName, attrObj }, e);
  }
};

export const createChatMessageLikeDispatcher = (
  chatMessageId,
  unlike = false
) => {
  return async (dispatch, getState) => {
    const likeType = unlike ? "unlike" : "like";
    const timeNow = new Date();
    const soonestTime = new Date();
    soonestTime.setSeconds(timeNow.getSeconds() - 3);
    if (lastLike < soonestTime) {
      const chatMessage = await updateEntityService.updateEntity(
        dispatch,
        getState,
        EntityType.ChatMessage,
        chatMessageId,
        null,
        likeType
      );
      dispatch(
        showMessageBarAction(
          true,
          `${likeType}d chatMessage from ${chatMessage.fromUser.fullName}`
        )
      );
      dispatch(
        updateCurrentListItemDispatcher(
          EntityType.ChatMessage,
          chatMessage,
          Operation.Update
        )
      );
      trackEvent(likeType + "ChatMessage", { chatMessageId });
      trackIntercomEvent(likeType + "ChatMessage", {
        chatMessageId,
      });
    } else dispatch(showErrorMessageBarAction(true, "liking too much!"));
    lastLike = new Date();
  };
};

export const trackConversion = () => {
  try {
    if (!getIsTestAgent()) {
      if (window.gtag)
        window.gtag("event", "conversion", {
          send_to: "AW-731004586/f8NaCO2VvKUBEKr9yNwC",
        });
      //LinkedIn Conversion event:
      if (window.lintrk) window.lintrk("track", { conversion_id: 7114292 });
    }
  } catch (e) {
    logError("trackConversion", {}, e);
  }
};

export const validateEntityNameDispatcher = (
  entityType,
  entityId,
  name,
  parentEntityType,
  parentEntityId
) => {
  return async (dispatch, getState) => {
    let queryObject = {
      name,
    };
    if (entityId) queryObject[entityType + "Id"] = entityId;
    const fetchEntityPath = parentEntityType
      ? `${parentEntityType}/${parentEntityId}/${entityType}`
      : entityType;
    const result = await fetchEntityService.fetchEntity(
      dispatch,
      getState,
      fetchEntityPath,
      false,
      "validateName",
      queryObject
    );
    dispatch(showErrorMessageBarAction(!result.valid, result.userMessage));
    return result.valid;
  };
};

export const subscriptionCheckoutDispatcher = (term) => {
  return async (dispatch, getState) => {
    const accountId = getState().current.entity.account.id;
    trackEvent("showSubscriptionCheckoutForm", { accountId });
    try {
      const result = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.Subscription,
        false,
        "checkoutUrl",
        { term }
      );
      if (window) window.location = result.url;
    } catch (e) {
      logError("subscriptionCheckoutDispatcher", { term }, e);
    }
  };
};

export const subscriptionPortalDispatcher = () => {
  return async (dispatch, getState) => {
    try {
      const result = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.Subscription,
        false,
        "portalUrl"
      );
      if (window) window.location = result.url;
    } catch (e) {
      logError("subscriptionPortalDispatcher", {}, e);
    }
  };
};

export const createPlaylistDispatcher = (formObject) => {
  return async (dispatch, getState) => {
    const playlist = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.Playlist,
      {},
      formObject
    );
    dispatch(showErrorMessageBarAction(false));
    trackIntercomEvent("create playlist", formObject);
  };
};

export const createPlaylistContentDispatcher = (form) => {
  return async (dispatch, getState) => {
    const playlistContent = await createEntityService.createEntity(
      dispatch,
      getState,
      EntityType.PlaylistContent,
      form
    );
    trackIntercomEvent("playlist content created", {
      playlistId: playlistContent.playlistId,
      contentId: playlistContent.contentId,
    });
    return playlistContent;
  };
};
